




import LookUp from '@/components/lookup/Index.vue'
import { authPermissions } from '@/constants'
import Vue from 'vue'

export default Vue.extend({
    name: 'Vendor',
    components: {
        LookUp
    },
    data(){
        return {
            options:{
                name: 'vendors',
                api: '/Vendor/GetVendors',
                exportAPI: {
                    api: '/Vendor/ExportVendors',
                    requiredPermissions: [authPermissions.canExportAll]
                },
                pivotTableAPI: {
                    api:'',
                    params: {
                        source: ''
                    }},
                fields:[
                    {
                        'name': 'vendorId',
                        'alias': 'Vendor ID',
                        'defaultOperator': 'exact match',
                        'type': 'string',
                        'dataTableOrder':0,
                    },
                    {
                        'name': 'vendorName',
                        'alias': 'Vendor Name',
                        'defaultOperator': 'contains',
                        'type': 'string',
                        'dataTableOrder':1
                    },
                    {
                        'name': 'address',
                        'alias': 'Address',
                        'defaultOperator': 'contains',
                        'type': 'string',
                        'dataTableOrder':2
                    },
                    {
                        'name': 'city',
                        'alias': 'City',
                        'defaultOperator': 'contains',
                        'type': 'string',
                        'dataTableOrder':3
                    },
                    {
                        'name': 'province',
                        'alias': 'Province',
                        'defaultOperator': 'contains',
                        'type': 'string',
                        'dataTableOrder':4
                    },
                    {
                        'name': 'postalCode',
                        'alias': 'Postal Code',
                        'defaultOperator': 'contains',
                        'type': 'string',
                        'dataTableOrder':5
                    },
                ],
                keywordSearchPlaceholder: 'Enter Vendor ID or Vendor Name or Address or City'
            }
        }
    },
})
